<template>
  <div>
    <div
      class="text-center d-flex justify-center white--text display-1 mt-4 mx-8"
    >
      Are you sure that you want to remove all history data?
    </div>
    <div class="d-flex justify-center white--text subtitle mt-4 mx-8">
      This action is irreversible except with Qwulo!!
    </div>
    <div class="d-flex justify-center mt-16 mx-8">
      <v-btn
        class="white--text mx-4"
        @click="handleDelete"
        color="red lighten-1"
      >
        Clear History
      </v-btn>
      <v-btn class="white--text mx-4" @click="$router.push('/')" color="blue">
        Cancel
      </v-btn>
    </div>
    <div class="d-flex justify-center">
      <div class="d-flex flex-column align-center justify-center mt-16 mx-8">
        <div class="display-1 white--text mb-6">Keys to be deleted:</div>
        <div class="d-inline-block white--text" v-for="key in keys" :key="key">
          {{ key }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  name: "ClearHistory",

  data() {
    return {
      keys: [],
    };
  },

  mounted() {
    this.handleLoadExistingKeys();
  },

  methods: {
    handleLoadExistingKeys() {
      const keys = Object.keys(localStorage).filter((key) =>
        /^zudo\.data\./.test(key)
      );
      this.keys = [...keys];
    },
    handleDelete() {
      const keysToDelete = Object.keys(localStorage).filter((key) =>
        /^zudo\.data\./.test(key)
      );
      keysToDelete.forEach((key) => {
        localStorage.removeItem(key);
      });

      this.$toast.success("Your Zudos have been deleted", {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
      });
      this.$router.push("/");
    },
  },
};
</script>
